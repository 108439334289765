import React, { useState } from 'react';
import { Link, graphql } from 'gatsby';

import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import SEO from '../components/seo';

import '../pages/styles.scss';

const formatDate = (date) => {
  let str = '';
  try {
    str = new Intl.DateTimeFormat('pl-PL', {
      year: 'numeric', month: 'long', day: 'numeric',
    }).format(new Date(date));
  } catch (e) {
    str = ` ${new Date(date).toString()}`;
  }
  str = ` ${str || new Date(date).toString()}`;
  return str;
};

const formatDateWeekday = (date) => {
  let str = '';
  try {
    str = new Intl.DateTimeFormat('pl-PL', {
      weekday: 'long',
    }).format(new Date(date));
  } catch (e) {
    str = ` ${new Date(date).toString()}`;
  }
  str = ` ${str || new Date(date).toString()}`;
  return str;
};

const condidions = {
  200: 'burza z lekkim deszczem',
  201: 'burza z deszczem',
  202: 'burza z silnym deszczem',
  210: 'lekka burza',
  211: 'burzowo',
  212: 'silna burza',
  221: 'burzowo',
  230: 'burza z lekką mżawką',
  231: 'burza i mżawaka',
  232: 'burza z mocniejszą mżawką',
  300: 'lekka, ale intensywna mżawka',
  301: 'mżawka',
  302: 'mocna, intensywna mżawka',
  310: 'lekka mżawka, przechodząca w deszcz',
  311: 'deszcz, czasami mżawka',
  312: 'dużo deszczu i mżawki',
  313: 'przelotne zrywy deszczu przeplatane mżawką',
  314: 'przelotnie ulewny i mżawka',
  321: 'przelotna mżawka',
  500: 'mały deszczyk',
  501: 'niewielki deszcz',
  502: 'mocny deszcz',
  503: 'bardzo dużo deszczu',
  504: 'mega deszczowo',
  511: 'deszcz marznący',
  520: 'lekki przelotny deszcz',
  521: 'przelotny deszczyk',
  522: 'silne przelotne deszcze',
  531: 'przelotne zrywy deszczu',
  600: 'śnieżek',
  601: 'śnieg',
  602: 'sporo śniegu',
  611: 'śnieg z deszczem',
  612: 'lekki przelotny śnieg z deszczem',
  613: 'przelotny śnieg z deszczem',
  615: 'lekki deszcz ze śniegiem',
  616: 'deszcz i śnieg',
  620: 'przelotny ale lekki śnieg',
  621: 'przelotny śnieg',
  622: 'sporo śniegu, przelotne opady',
  701: 'mgła',
  711: 'mgła',
  721: 'mgła',
  731: 'pył',
  741: 'mgła',
  751: 'piasek',
  761: 'pył',
  762: 'pył wulkaniczny',
  771: 'szkwał',
  781: 'tornado',
  800: 'słonecznie',
  801: 'troszkę chmur',
  802: 'trochę chmur',
  803: 'dużo chmur',
  804: 'bardzo dużo chmur',
};

const getCondition = (id) => {
  const numbId = parseInt(id);
  return condidions[numbId] || '';
};

const printTemp = (temp) => `${Math.round(temp)}℃`;

export default function Template({ pageContext, location, data }) {
  const {
    pdop, title, date, description, weather, icons, name, wtxt, cities, country,
  } = pageContext;

  const todayTimestamp = new Date(new Date(new Date().setHours(0)).setMinutes(0)).getTime();

  const [days, setDays] = useState(weather.filter((el) => el.dt >= todayTimestamp));

  const image = getImage(data.chart);
  const imageSrc = getSrc(data.chart);

  const meta = [
    {
      itemprop: 'image',
      content: imageSrc,
    },
    {
      property: 'og:image',
      content: imageSrc,
    },
    {
      property: 'twitter:image',
      content: imageSrc,
    },
  ];

  const imgAlt = `${name} prognoza pogody na najbliższe 2 tygodnie`;

  const jsonld = {
    '@context': 'http://schema.org',
    '@type': 'ImageObject',
    author: 'pogodapodroze.pl',
    contentLocation: name,
    contentUrl: imageSrc,
    datePublished: new Date().toJSON().slice(0, 10),
    description: `${name} prognoza podody i wykres zmian temperatury w najbliżych tygodniach`,
    name: imgAlt,
  };

  const getIcon = (id) => {
    const ico = icons.find((el) => el.name == id.toString());
    if (!ico) {
      const newId = Math.round((parseInt(id) / 100)) * 100;
      return icons.find((el) => el.name == newId.toString());
    }

    return ico;
  };
  return (
    <Layout>
      <SEO title={title} description={description} meta={meta} pathname={location.pathname} jsonld={jsonld} />
      <div className="section">
        <div className="container">
          <div className="blog-post">
            <h1 className="title">{title}</h1>
            <div className="block mt-5 mb-5" dangerouslySetInnerHTML={{ __html: wtxt}} />

            <div className="tags mb-5">
              { !!country && (
              <span className="tag is-light is-medium"><a title={`${country.name} pogoda na najbliższy czas`} href={country.slug}>{ country.name }</a></span>
              )}
              { !!cities && cities.length > 0 && (
              <>
                { cities.map((city) => (
                  <span className={`tag is-medium ${city.name != name ? 'is-light' : 'is-primary is-light'}`}>
                    <Link title={`${city.name} pogoda na najbliższy czas`} to={city.slug}>{ city.name }</Link>
                  </span>
                ))}
              </>
              )}
            </div>

            <div className="has-text-centered mb-5">
              <a href={imageSrc} title="imgAlt" target="_blank" rel="noreferrer">
                <GatsbyImage
                  alt={imgAlt}
                  image={image}
                  placeholder="blurred"
                  layout="fullWidth"
                />
              </a>
              <h2>
                Jaka pogoda nas czeka w najbliższym czasie w $
                {pdop}
                ?
              </h2>
            </div>

            <div className="columns is-multiline">
              { days.map((day) => (
                <div className="column is-full-mobile is-half-tablet is-one-quarter-desktop is-one-quarter-widescreen">
                  <div className="card">
                    <div className="card-content">
                      <div className="media">
                        <div className="media-left">
                          <figure className="image is-48x48">
                            <GatsbyImage
                              alt={day.id.toString()}
                              image={getImage(getIcon(day.id))}
                              placeholder="blurred"
                            />
                          </figure>
                        </div>
                        <div className="media-content">
                          <p className="title is-4">{ formatDateWeekday(new Date(day.dt)) }</p>
                          <p className="subtitle is-6">{ formatDate(new Date(day.dt)) }</p>
                        </div>
                      </div>

                      <div className="content">

                        { !!day.temp?.day && (
                        <div className="big-temp is-success has-text-centered">
                          { printTemp(day.temp.day) }
                        </div>
                        )}
                        <div className="tags">
                          { !!day.temp?.night && (
                          <span className="tag is-light is-small">
                            W nocy:&nbsp;
                            { printTemp(day.temp.night) }
                          </span>
                          )}
                          { !!day.temp?.morn && (
                          <span className="tag is-light is-small">
                            Nad ranem:&nbsp;
                            { printTemp(day.temp.morn) }
                          </span>
                          )}
                          { !!day.temp?.eve && (
                          <span className="tag is-light is-small">
                            Wieczorem:&nbsp;
                            { printTemp(day.temp.eve) }
                          </span>
                          )}
                          { !!getCondition(day.id) && (
                          <span className="tag is-light is-small">
                            { getCondition(day.id) }
                          </span>
                          )}
                          { !!day.pressure && (
                          <span className="tag is-light is-small">
                            Ciśnienie:&nbsp;
                            { day.pressure }
                          </span>
                          )}
                          { !!day.humidity && (
                          <span className="tag is-light is-small">
                            Wilgotność:&nbsp;
                            { day.humidity }
                            %
                          </span>
                          )}
                          { !!day.windspeed && (
                          <span className="tag is-light is-small">
                            Wiatr:&nbsp;
                            { Math.round(day.windspeed) }
                          </span>
                          )}
                          { !!day.clouds && (
                          <span className="tag is-light is-small">
                            Zachmurzenie:&nbsp;
                            { day.clouds }
                            %
                          </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
query($image: String!) {
  chart: file(relativePath: { eq: $image }) {
      childImageSharp {
        gatsbyImageData(
         layout: FULL_WIDTH
         quality: 50
         placeholder: BLURRED
         formats: [AUTO, WEBP]
         breakpoints: [800, 1600]
        )
      }
    }

}
`;

// gatsbyImageData(
//   width: 500
//   quality: 50
//   placeholder: BLURRED
//   formats: [AUTO, WEBP]
//  )
